import React from 'react';

import {
  BannerWarning,
  LocaleSelection,
  ServiceCardButton,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import DineInImage from '../../../assets/images/dinein.svg';
import TakeawayImage from '../../../assets/images/takeaway.svg';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultHeroImage from '../../../assets/images/bucket.png';
import DefaultBackgroundImage from '../../../assets/images/background.png';

const SecondaryHome = ({
  t = () => {},
  className = '',
  languages = [],
  healthMsg = true,
  primaryAction,
  secondaryAction,
  onChangeLanguage = () => {},
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackgroundImage,
        },
        hero: {
          image: DefaultHeroImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="secondary-home"
        className={`z-10 flex flex-col justify-between items-center h-full w-full overflow-y-hidden  ${className}`}
      >
        <div
          data-test="home-upper"
          className="flex flex-col items-center justify-start w-full h-full"
        >
          {!accessibilityEnabled && <HomeCustomisation.HeroImage />}
        </div>

        <div
          data-test="home-lower"
          className="flex flex-col-reverse items-center justify-start w-full h-full"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              className={`w-4/5 mb-${healthMsg ? '2' : '32'}`}
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={WashHandsImage}
              className={`w-4/5 mb-${showTermsAndConditionsButton ? '2' : '32'}`}
            />
          )}

          <div
            data-test="home-services"
            className={`flex items-stretch justify-center w-4/5 ${
              healthMsg ? 'mb-16' : 'mb-72'
            } space-x-16`}
          >
            {!!secondaryAction ? (
              <ServiceCardButton
                data-test="button-secondary"
                className="w-40 h-40 px-4 pt-0 pb-6 shadow-lg bg-secondary lg:px-8 lg:h-80 lg:w-home-button rounded-home-button"
                onClick={secondaryAction}
              >
                <div className="mt-0 text-3xl break-all lg:mb-0 lg:mt-4 lg:text-6xl">
                  {t('homeSecondaryAction')}
                </div>

                <DineInImage className="invisible w-16 h-16 lg:w-48 lg:h-48 lg:visible" />
              </ServiceCardButton>
            ) : null}

            {!!primaryAction ? (
              <ServiceCardButton
                data-test="button-primary"
                className="w-40 h-40 pt-0 pb-6 shadow-lg bg-secondary l lg:h-80 lg:w-home-button rounded-home-button"
                onClick={primaryAction}
              >
                <div className="mt-0 text-3xl break-all lg:mb-0 lg:mt-4 lg:text-6xl">
                  {t('homePrimaryAction')}
                </div>

                <TakeawayImage className="invisible w-16 h-16 lg:w-48 lg:h-48 lg:visible" />
              </ServiceCardButton>
            ) : null}
          </div>
        </div>

        <div className="flex justify-center w-full mt-8" data-test="home-footer">
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
